.amplify-button[data-variation="primary"],
.amplify-button:active,
.amplify-button:focus,
.amplify-button:hover {
  background-color: #1495f1 !important;
}

.amplify-tabs-item[data-state="active"],
[data-amplify-authenticator] [data-amplify-router],
.amplify-input {
  border-color: #1495f1 !important;
}

.amplify-button--link,
.amplify-tabs-item[data-state="active"] {
  color: #1495f1 !important;
}

.tracker-icon {
  border-radius: 50%;
  border: 3px solid #1495f1 !important;
}
